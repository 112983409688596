import React, { useState, useEffect, useContext } from "react";
import "./TextArea.css";
import "../styles/theme/codemirror.css";
import "../styles/theme/monkeytype.css";
import "../styles/theme/lightmode.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/lint/json-lint";
import jsonlint from "jsonlint/lib/jsonlint";
import Json from "./Json";
import { validateJSON, validateJSONL } from "../utils/heplers";
import ThemeSwitcher from "./ThemeSwitcher";
import { ThemeContext } from "../Theme";

window.jsonlint = jsonlint;
const TextArea = ({
  inputJSON,
  setInputJSON,
  jsonData,
  setJsonData,
  setTableFlag,
  tableFlag,
  showDataType,
  setShowDataType,
}) => {
  const [errorMesssage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [autoValidate, setAutoValidate] = useState(false);
  const [spaceErrorMessage, setSpaceErrorMessage] = useState("");
  const [spaces, setSpaces] = useState(2);
  const [isCopied, setIsCopied] = useState(false);
  const [inputFormat, setInputFormat] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const savedTheme = localStorage.getItem("tabularjsontheme");
    if (savedTheme === "dark" && theme === "light") {
      toggleTheme();
    } else if (savedTheme === "light" && theme === "dark") {
      toggleTheme();
    } else {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches &&
        theme === "light"
      ) {
        toggleTheme();
      } else if (theme === "dark") {
        toggleTheme();
      }
    }
    //eslint-disable-next-line
  }, []);

  const realTimeValidation = (value) => {
    if (!value) setErrorMessage("");
    else {
      const { valid: isValidJSON, message: validationJSONMessage } =
        validateJSON(value);
      const { valid: isValidJSONL, message: validationJSONLMessage } =
        validateJSONL(value);
      if (isValidJSON) {
        setErrorMessage("");
        setSuccessMessage(validationJSONMessage);
        setInputFormat("JSON");
      } else if (isValidJSONL) {
        setErrorMessage("");
        setSuccessMessage(validationJSONLMessage);
        setInputFormat("JSONL");
      } else {
        setErrorMessage(validationJSONLMessage);
        setSuccessMessage("");
        setInputFormat(null);
      }
    }
  };

  const handleClick = (e) => {
    e?.preventDefault();

    if (inputFormat === "JSONL") {
      try {
        setJsonData(
          JSON?.parse(
            "[".concat(inputJSON.trim().replace(/\n/g, ",")).concat("]")
          )
        );
      } catch ({ message }) {
        message
          ? setErrorMessage(`JSONL is invalid: ${message}`)
          : setErrorMessage("");
      }
    } else {
      try {
        const numberOfSpaces = parseInt(spaces);
        if (!numberOfSpaces) {
          setSpaces(2);
          setInputJSON(JSON?.stringify(JSON?.parse(inputJSON), null, 2));
        }
        setJsonData(JSON?.parse(inputJSON));
      } catch ({ message }) {
        message
          ? setErrorMessage(`JSON is invalid: ${message}`)
          : setErrorMessage("");
      }
    }
  };

  const copyTextFromTextArea = () => {
    navigator?.clipboard?.writeText(inputJSON);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  };

  const toggleDataType = () => {
    setShowDataType(!showDataType);
  };

  const jsonComponentProps = {
    toggleDataType,
    copyTextFromTextArea,
    handleClick,
    realTimeValidation,
    spaces,
    setSpaces,
    errorMesssage,
    setErrorMessage,
    inputJSON,
    setInputJSON,
    jsonData,
    setJsonData,
    setTableFlag,
    tableFlag,
    showDataType,
    setShowDataType,
    successMessage,
    setSuccessMessage,
    setIsCopied,
    isCopied,
    autoValidate,
    setAutoValidate,
    toggleTheme,
    spaceErrorMessage,
    setSpaceErrorMessage,
    inputFormat,
    setInputFormat,
  };

  return (
    <>
      <div className="container">
        <div>
          <div className="main-heading">
            <h1 className="p-relative header-text-container">
              <div className="header-text">
                TabularJSON by <a href="https://datumbrain.com/">Datum Brain</a>
              </div>
              <ThemeSwitcher />
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="mr-auto">
            <div className="empty-container-right">&nbsp;</div>
          </div>
        </div>
        <Json jsonComponentProps={jsonComponentProps} />
      </div>
    </>
  );
};
export default TextArea;
